














































import { inject } from 'inversify-props';
import { Vue, Component } from 'vue-property-decorator';
import CallCenterListItem from '@/components/crm/call-center-overview-item.vue';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import CallCenterCallService from '@/services/crm/call-center-call.service';
import CallCenterOverviewModel from '@/models/crm/call-center-overview.model';
import CallCenterCallModel from '@/models/crm/call-center-call.model';

@Component({
  components: {
    CallCenterListItem,
  },
})
export default class CrmCallCenterOverview extends Vue {
  tab: string | null = 'tab-active';

  @inject(InjectionIdEnum.CallCenterCallService)
  private callCenterCallService!: CallCenterCallService;

  items: CallCenterOverviewModel[] = [];

  quantityCallInProgress = '0';

  async mounted(): Promise<void> {
    await this.loadOverviewItems();
  }

  onClickPrevious(): void {
    this.$router.back();
  }

  private async loadOverviewItems() {
    await this.callCenterCallService.getOverview().then((result: CallCenterOverviewModel[]) => {
      this.items = result;
      this.setCallInProgress();
    });
  }

  private async setCallInProgress() {
    if (this.items.length <= 0) {
      this.quantityCallInProgress = '0';
    }

    let calls: CallCenterCallModel[] = [];
    this.items.forEach((x) => {
      calls = calls.concat(x.ligacoes.filter((l) => l.dataAtendimento != null && l.dataFinalizacao == null));
    });

    this.quantityCallInProgress = calls.length.toString();
  }
}
