





























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import CallCenterCallModel from '@/models/crm/call-center-call.model';
import CallCenterOverviewModel from '@/models/crm/call-center-overview.model';

@Component({})
export default class CrmCallCenterOverviewItem extends Vue {
  @Prop()
  item!: CallCenterOverviewModel;

  callInProgress: CallCenterCallModel | null = null;

  totalCallsForDay = 0;

  get isIdentifiedCustomer(): boolean {
    return !!this.callInProgress?.nomeContato;
  }

  @Watch('item', { immediate: true })
  private renderItem(): void {
    if (this.item) {
      if (this.item.ligacoes.length > 0) {
        const itemInProgress = this.item.ligacoes.find(
          (item) => item.dataAtendimento != null && item.dataFinalizacao == null,
        );

        if (itemInProgress) {
          this.callInProgress = itemInProgress;
        }

        const callsForDay = this.item.ligacoes.filter((item) => item.dataFinalizacao != null);

        this.totalCallsForDay = callsForDay.length;
      } else {
        this.totalCallsForDay = 0;
        this.callInProgress = null;
      }
    }
  }

  getContactName(): string {
    if (this.isIdentifiedCustomer && this.callInProgress) {
      return this.callInProgress.nomeContato;
    }

    return this.$t('crm.callCenter.callInProgress.contactNotFound').toString();
  }
}
